import _ from 'lodash'
import moment from 'moment'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

import TimeCard, { calcTotalTime, START_MONTH } from './TimeCard'

const { REACT_APP_ADMIN_UID, REACT_APP_UIDS } = process.env

const userIds = REACT_APP_UIDS?.split(',')

export default class Admin extends TimeCard {
  componentDidMount() {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (!user || user.uid !== REACT_APP_ADMIN_UID) {
        document.location.href = '/'
      }
    })
  }

  onChangeUser = (e: any) => {
    const uid = e.target.value

    if (!uid) {
      return
    }

    const { month } = this.state
    const user = { uid }

    this.setState({ user, month: Number(month), timeLogs: [] }, () => {
      this.fetchTimeLogs(uid)
    })
  }

  render() {
    const { timeLogs, month } = this.state

    const { hours, minutes } = calcTotalTime(timeLogs)
    const currentMonth = new Date().getMonth() + 1

    const months = _.times(currentMonth - START_MONTH + 1, (index) => START_MONTH + index)

    return (
      <div className="mx-3 my-2">
        <div className="text-center">
          {/* ユーザー選択 */}
          <select onChange={this.onChangeUser}>
            <option value=""></option>
            {userIds?.map((userId: string) => {
              const [key, value] = userId.split(':')
              return (
                <option key={key} value={value}>
                  {key}
                </option>
              )
            })}
            <option value={REACT_APP_ADMIN_UID}>y</option>
          </select>
          {/* 月選択 */}
          <select onChange={this.onChangeMonth} value={month}>
            {months.map((m: number) => (
              <option key={m} value={m}>
                {m}
              </option>
            ))}
          </select>
          月の労働時間:
          <span className="fs-3">{hours}</span>
          <span>時間</span>
          <span className="fs-3">{minutes}</span>
          <span>分</span>
        </div>

        <table className="table table-striped">
          <thead>
            <tr>
              <th className="text-center">開始</th>
              <th className="text-center">終了</th>
            </tr>
          </thead>
          <tbody>
            {timeLogs.map((timeLog: any) => (
              <tr key={timeLog.start_date.seconds}>
                <td className="text-end">
                  {moment.unix(timeLog.start_date.seconds).format('YYYY-MM-DD HH:mm:ss')}
                </td>
                <td className="text-end">
                  {timeLog.stop_date
                    ? moment.unix(timeLog.stop_date.seconds).format('YYYY-MM-DD HH:mm:ss')
                    : ''}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}
