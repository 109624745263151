import React, { useEffect, useRef, useState } from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import assert from 'assert'
import { formatRelativeTime } from '../../common/date'
import { Log, LogType, User } from './types'
import { fetchLogs } from './fetchLogs'

const convertToLogType: { [key: string]: LogType } = {
  ' ': 'start',
  '  ': 'wip',
} as const

class DateEx extends Date {
  get seconds() {
    return this.getTime() / 1000
  }
}

export const LifeLogPage = () => {
  const [user, setUser] = useState<User>({
    uid: '',
    displayName: '',
  })
  const [logs, setLogs] = useState<Log[]>([])
  const inputRef = useRef<HTMLInputElement>(null)
  const ulRef = useRef<HTMLUListElement>(null)

  // TODO start or stop, start のときは入力値を空にせずそのまま残す
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    assert.ok(user?.uid, 'ERROR: user.uid is null')
    assert.ok(inputRef.current)

    const content = inputRef.current.value
    const matches = content.match(/ +$/) ?? []
    const [spaces = ''] = matches

    const log: Omit<Log, 'id'> = {
      content: content.trim(),
      datetime: new DateEx(),
      type: convertToLogType[spaces] ?? 'stop',
    }

    const db = firebase.firestore()
    db.collection(`users/${user.uid}/lifeLogs`).add(log)

    setLogs(
      logs.concat({
        id: Date.now().toString(),
        ...log,
      })
    )

    inputRef.current.value = ''

    setTimeout(() => {
      assert.ok(ulRef.current)
      ulRef.current.scrollTop = ulRef.current.scrollHeight
    })
  }

  const onUseClick = (id: string) => {
    assert.ok(inputRef.current)

    const log = logs.find((log) => log.id === id)
    assert.ok(log)

    inputRef.current.value = log.content
    inputRef.current.focus()
  }

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        // User is signed in.
        setUser({
          uid: user.uid,
          displayName: user.displayName ?? '',
        })

        fetchLogs(user.uid).then((data) => {
          setLogs(data)

          setTimeout(() => {
            assert.ok(ulRef.current)
            ulRef.current.scrollTop = ulRef.current.scrollHeight
            assert.ok(inputRef.current)
            inputRef.current.focus()
          })
        })
      } else {
        // No user is signed in.
      }
    })

    return unsubscribe
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="m-auto my-2" style={{ width: '100%', maxWidth: 300, overflow: 'hidden' }}>
      <ul
        className="list-group mb-2"
        style={{ height: 'calc(100vh - 62px)', overflowY: 'scroll' }}
        ref={ulRef}
      >
        {logs.map(({ id, type, datetime, content }) => (
          <li
            key={id}
            className="list-group-item d-flex"
            style={{ cursor: 'pointer' }}
            onClick={() => onUseClick(id)}
          >
            <div className="me-2">{formatRelativeTime(datetime.seconds)}</div>
            <div className="badge bg-info me-2">{type}</div>
            <div>{content}</div>
          </li>
        ))}
      </ul>

      <form onSubmit={onSubmit}>
        <div className="input-group">
          <input
            name="content"
            type="text"
            className="form-control"
            enterKeyHint="send"
            ref={inputRef}
            style={{ boxShadow: 'none' }}
          />
          <button type="submit" className="btn btn-outline-primary">
            send
          </button>
        </div>
      </form>
    </div>
  )
}
