import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import { Log } from './types'

export async function fetchLogs(uid: string) {
  const db = firebase.firestore()
  const ref = db.collection(`users/${uid}/lifeLogs`).orderBy('datetime', 'desc').limit(50)
  const snapshot = await ref.get()
  return snapshot.docs
    .map(
      (doc) =>
        ({
          id: doc.id,
          ...doc.data(),
        } as Log)
    )
    .reverse()
}
