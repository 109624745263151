import { HashRouter, Switch, Route } from 'react-router-dom'
import Admin from './pages/Admin'
import { LifeLogPage } from './pages/LifeLog/LifeLogPage'
import TimeCard from './pages/TimeCard'

export default function Routing() {
  return (
    <HashRouter>
      <Switch>
        <Route path="/admin">
          <Admin />
        </Route>
        <Route exact path="/">
          <TimeCard />
        </Route>
        <Route path="/life-log">
          <LifeLogPage />
        </Route>
      </Switch>
    </HashRouter>
  )
}
