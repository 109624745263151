import { Component, FormEvent } from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import moment from 'moment'
import _ from 'lodash'

const { REACT_APP_NOTIFY_SLACK_URL } = process.env
export const START_MONTH = 1

interface User {
  displayName: string
  uid: string
}

interface State {
  user: User
  timeLogs: any[]
  month: number
}

export default class TimeCard extends Component {
  state: State = {
    user: {
      displayName: '',
      uid: '',
    },
    timeLogs: [],
    month: new Date().getMonth() + 1,
  }

  unsubscribe?: () => void

  componentDidMount() {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        // User is signed in.
        this.setState({ user })

        this.fetchTimeLogs(user.uid)
      } else {
        // No user is signed in.
      }
    })
  }

  async fetchTimeLogs(uid: string) {
    const { month } = this.state

    // TODO 年またぎを考慮
    const thisMonth = month.toFixed()
    const nextMonth = (month + 1).toFixed()

    const db = firebase.firestore()
    const ref = await db
      .collection(`users/${uid}/timeLogs`)
      .where('start_date', '>=', moment(`2023-${thisMonth}-01 00:00`, 'YYYY-MM-DD HH:mm').toDate())
      .where('start_date', '<', moment(`2023-${nextMonth}-01 00:00`, 'YYYY-MM-DD HH:mm').toDate())
      .orderBy('start_date', 'asc')

    if (this.unsubscribe) {
      this.unsubscribe()
    }

    this.unsubscribe = ref.onSnapshot((snapshot: any) => {
      snapshot.docChanges().forEach((change: any) => {
        const data = change.doc.data()

        if (change.type === 'added') {
          // console.log('New: ', data)
          this.setState({
            timeLogs: this.state.timeLogs.concat({ id: change.doc.id, ...data }),
          })
        } else if (change.type === 'modified') {
          // console.log('Modified: ', data)
          const timeLogs = [...this.state.timeLogs]
          timeLogs[timeLogs.length - 1] = { id: change.doc.id, ...data }

          this.setState({ timeLogs })
        } else if (change.type === 'removed') {
          console.log('Removed: ', data)
        }
      })
    })
  }

  onSubmitLogin = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    var provider = new firebase.auth.GoogleAuthProvider()
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        // var credential: any = result.credential

        // This gives you a Google Access Token. You can use it to access the Google API.
        // var token = credential.accessToken
        // The signed-in user info.
        var user: any = result.user

        this.setState({ user })

        const db = firebase.firestore()
        db.collection('users').doc(user.uid).set({ name: user.displayName })
      })
      .catch((error) => {
        console.error(error)

        // // Handle Errors here.
        // var errorCode = error.code
        // var errorMessage = error.message
        // // The email of the user's account used.
        // var email = error.email
        // // The firebase.auth.AuthCredential type that was used.
        // var credential = error.credential
      })
  }

  startWorking = () => {
    const { user } = this.state

    // 現在時刻を保存する
    const db = firebase.firestore()
    db.collection(`users/${user.uid}/timeLogs`).add({
      start_date: new Date(),
    })

    // TODO show toast

    fetch(`${REACT_APP_NOTIFY_SLACK_URL}?type=start&name=${user.displayName.substr(0, 1)}`)
  }

  stopWorking = () => {
    const { user, timeLogs } = this.state

    const lastTimeLog = _.last(timeLogs)

    // 現在時刻を保存する
    const db = firebase.firestore()
    db.collection(`users/${user.uid}/timeLogs`).doc(lastTimeLog.id).set(
      {
        stop_date: new Date(),
      },
      { merge: true }
    )

    fetch(`${REACT_APP_NOTIFY_SLACK_URL}?type=stop&name=${user.displayName.substr(0, 1)}`)
  }

  onChangeMonth = (e: any) => {
    const month = e.target.value
    this.setState({ month: Number(month), timeLogs: [] }, () => {
      const { user } = this.state
      this.fetchTimeLogs(user.uid)
    })
  }

  comment() {
    //
  }

  tweet() {
    //
  }

  render() {
    const { user, timeLogs, month } = this.state
    const lastTimeLog = _.last(timeLogs) || {}

    const { hours, minutes } = calcTotalTime(timeLogs)
    const currentMonth = new Date().getMonth() + 1
    const canRecord = month === currentMonth

    const months = _.times(currentMonth - START_MONTH + 1, (index) => START_MONTH + index)

    const isWorking = !(timeLogs.length === 0 || Boolean(lastTimeLog.stop_date))

    return (
      <div className="m-auto my-2" style={{ width: '100%', maxWidth: 300 }}>
        <div className="d-flex justify-content-between">
          <div>タイムカード</div>
          {Boolean(user.displayName) ? (
            <div>
              <span className="me-1">{user.displayName}</span>
              <span>様</span>
            </div>
          ) : (
            <form onSubmit={this.onSubmitLogin}>
              <button type="submit">ログイン</button>
            </form>
          )}
        </div>

        <div className="text-center">{isWorking ? '作業中' : ''}</div>

        <div className="d-flex justify-content-around">
          {timeLogs.length === 0 || Boolean(lastTimeLog.stop_date) ? (
            <button
              className="btn btn-primary m-2"
              onClick={this.startWorking}
              disabled={!canRecord}
            >
              開始
            </button>
          ) : (
            <button
              className="btn btn-primary m-2"
              onClick={this.stopWorking}
              disabled={!canRecord}
            >
              終了
            </button>
          )}
        </div>

        <div className="text-center">
          <select onChange={this.onChangeMonth} value={month}>
            {months.map((m: number) => (
              <option key={m} value={m}>
                {m}
              </option>
            ))}
          </select>
          月の労働時間:
          <span className="fs-3">{hours}</span>
          <span>時間</span>
          <span className="fs-3">{minutes}</span>
          <span>分</span>
        </div>

        <table className="table table-striped">
          <thead>
            <tr>
              <th colSpan={2} className="text-center">
                開始
              </th>
              <th colSpan={2} className="text-center">
                終了
              </th>
            </tr>
          </thead>
          <tbody>
            {timeLogs.map((timeLog: any) => (
              <tr key={timeLog.start_date.seconds}>
                <td className="text-end">
                  {moment.unix(timeLog.start_date.seconds).format('M/D')}
                </td>
                <td className="text-end">
                  {moment.unix(timeLog.start_date.seconds).format('H:mm')}
                </td>
                <td className="text-end">
                  {timeLog.stop_date ? moment.unix(timeLog.stop_date.seconds).format('M/D') : ''}
                </td>
                <td className="text-end">
                  {timeLog.stop_date ? moment.unix(timeLog.stop_date.seconds).format('H:mm') : ''}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* <form onSubmit={this.comment} className="mb-2">
          <input type="text" placeholder="訂正があるときはここに書き込む" />
          <button type="submit">コメント</button>
        </form>

        <form onSubmit={this.tweet}>
          <div>
            <textarea placeholder="ひとりごと" />
          </div>
          <div>
            <button type="submit">送信</button>
          </div>
        </form> */}
      </div>
    )
  }
}

export function calcTotalTime(timeLogs: any[]): any {
  const total = timeLogs.reduce((ac, timeLog) => {
    return Boolean(timeLog.stop_date)
      ? ac + timeLog.stop_date.seconds - timeLog.start_date.seconds
      : ac
  }, 0)
  const min = total / 60
  return {
    hours: Math.floor(min / 60),
    minutes: Math.floor(min % 60),
  }
}
